import React, { useEffect } from "react"
import { useIntl } from "gatsby-plugin-intl"

import "../styles/pages/Platform.scss"

import Hero from "../assets/images/platform-compute-image.png"

import Layout from "../components/Layout.jsx"
import Seo from "../components/Seo"

export default function Platform() {
  const intl = useIntl()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Layout>
      <Seo title={intl.formatMessage({ id: "platform-title" })} />
      <main className="platform">
        <section className="platform__hero">
          <div className="platform__container">
            <h2 className="platform__hero-title">
              {intl.formatMessage({ id: "platform-title" })}
            </h2>
            <div className="platform__hero-wrapper">
              <img src={Hero} alt="Platform" className="platform__hero-image" />
            </div>
          </div>
        </section>
        <section className="platform__intro">
          <div className="platform__container">
            <h2 className="platform__intro-title">
              {intl.formatMessage({ id: "platform-subtitle" })}
            </h2>
            <p className="platform__intro-paragraph">
              {intl.formatMessage({ id: "platform-paragraph" })}
            </p>
          </div>
        </section>
        <section className="platform__more">
          <div className="platform__container">
            <h2 className="platform__more-title">
              {intl.formatMessage({ id: "platform-more-title" })}
            </h2>
            <div className="platform__more-wrapper">
              <ul className="platform__more-list">
                <li className="platform__more-item platform__more-item--left">
                  {intl.formatMessage({ id: "platform-more-key-one" })}
                </li>
                <li className="platform__more-item platform__more-item--right">
                  {intl.formatMessage({ id: "platform-more-key-two" })}
                </li>
                <li className="platform__more-item platform__more-item--left">
                  {intl.formatMessage({ id: "platform-more-key-three" })}
                </li>
                <li className="platform__more-item platform__more-item--right">
                  {intl.formatMessage({ id: "platform-more-key-four" })}
                </li>
                <li className="platform__more-item platform__more-item--left">
                  {intl.formatMessage({ id: "platform-more-key-five" })}
                </li>
                <li className="platform__more-item platform__more-item--right">
                  {intl.formatMessage({ id: "platform-more-key-six" })}
                </li>
                <li className="platform__more-item platform__more-item--left">
                  {intl.formatMessage({ id: "platform-more-key-seven" })}
                </li>
                <li className="platform__more-item platform__more-item--right">
                  {intl.formatMessage({ id: "platform-more-key-eight" })}
                </li>
              </ul>
            </div>
          </div>
        </section>
        {/*        
        <section className="platform__section-two">
          <h2 className="platform__section-two-title">
            {intl.formatMessage({ id: "platform-more-title" })}
          </h2>
          <ul className="platform__section-two-list">
            <li className="platform__section-two-list-item">
              {intl.formatMessage({ id: "platform-more-key-one" })}
            </li>
            <li className="platform__section-two-list-item">
              {intl.formatMessage({ id: "platform-more-key-two" })}
            </li>
            <li className="platform__section-two-list-item">
              {intl.formatMessage({ id: "platform-more-key-three" })}
            </li>
            <li className="platform__section-two-list-item">
              {intl.formatMessage({ id: "platform-more-key-four" })}
            </li>
          </ul>
          <ul className="platform__section-two-list">
            <li className="platform__section-two-list-item">
              {intl.formatMessage({ id: "platform-more-key-five" })}
            </li>
            <li className="platform__section-two-list-item">
              {intl.formatMessage({ id: "platform-more-key-six" })}
            </li>
            <li className="platform__section-two-list-item">
              {intl.formatMessage({ id: "platform-more-key-seven" })}
            </li>
            <li className="platform__section-two-list-item">
              {intl.formatMessage({ id: "platform-more-key-eight" })}
            </li>
          </ul>
        </section> */}
      </main>
    </Layout>
  )
}
